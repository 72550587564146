 <template>
	<div class="page">


		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item label-width="0">
				<el-button type="primary" @click="menu_add(0)">添加主菜单</el-button>
			</el-form-item>
		</el-form>

	
		<!-- 表格 -->
		<div class="tab_height">
			<el-table 
				:data="data" 
				:border="true" 
				:stripe="true" 
				size="small" 
				height="100%" 
				v-loading="loading"
				row-key="id"
				:tree-props="{children: 'children'}"
			>
				<el-table-column label="ID" prop="id" width="80"></el-table-column>

				<el-table-column label="菜单名称/地址">
					<template slot-scope="scope">
						<div v-if="scope.row.fid==0">{{scope.row.name}}</div>
						<div style="margin-left:40px" v-if="scope.row.fid!=0">{{scope.row.name}} 链接:{{scope.row.url}}</div>
					</template>
				</el-table-column>

				<!-- 状态 -->
				<el-table-column label="状态" width="180">
					<template slot-scope="scope">
						<el-input v-model="scope.row.status_text" disabled>
							<el-button @click="status_click(scope.row)" slot="append">{{scope.row.status_btn}}</el-button>
						</el-input>
					</template>
				</el-table-column>
				
				<!-- 排序 -->
				<el-table-column label="排序" width="150">
					<template slot-scope="scope">
						<el-button @click="menu_move(scope.row.id,'up')" size="mini" type="success">
							<span style="font-family: ali_iconfont;">&#xe682;</span>
						</el-button>
						<el-button @click="menu_move(scope.row.id,'down')" size="mini" type="primary">
							<span style="font-family: ali_iconfont;">&#xe668;</span>
						</el-button>
					</template>
				</el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="310">
					<template slot-scope="scope">
						<el-button @click="menu_edit(scope.row)" size="mini" type="primary" icon="el-icon-edit">修改</el-button>
						<el-button @click="menu_del(scope.row)" size="mini" type="danger" icon="el-icon-circle-close">删除</el-button>
						<el-button @click="menu_add(scope.row.id)" v-if="scope.row.fid==0" size="mini" type="success" icon="el-icon-circle-check">添加子栏目</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>


		<!-- 添加表单 -->
		<el-dialog top="3vh" title="添加菜单" width="400px" :visible.sync="menu_add_para.is_show">
			<el-form status-icon label-position="left" label-width="70px">
				<el-form-item label="上级菜单" >
					<el-input v-model="menu_add_para.fname" :disabled="true"></el-input>
				</el-form-item>
			</el-form>
			<el-form status-icon label-position="left" label-width="70px">
				<el-form-item label="菜单名称" >
					<el-input v-model="menu_add_para.name" ></el-input>
				</el-form-item>
			</el-form>
			<el-form status-icon label-position="left" label-width="70px">
				<el-form-item label="链接地址" >
					<el-input v-model="menu_add_para.url" ></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" @click="menu_add_sub">提交</el-button>
			</div>
		</el-dialog>


		<!-- 修改表单 -->
		<el-dialog top="3vh" title="修改菜单" width="400px" :visible.sync="menu_edit_para.is_show">
			<el-form status-icon label-position="left" label-width="70px">
				<el-form-item label="菜单名称" >
					<el-input v-model="menu_edit_para.name" ></el-input>
				</el-form-item>
			</el-form>
			<el-form status-icon label-position="left" label-width="70px">
				<el-form-item label="链接地址" >
					<el-input v-model="menu_edit_para.url" ></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" @click="menu_edit_sub">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				is_show:false,

				//loading特效
				loading: false,
				
				//列表数据
				data: [],

				//缓存数据
				menu_buf:{},

				//菜单添加
				menu_add_para:{
					fname:'',
					name:'',
					url:'',
					is_show:false,
					fid:0,
				},

				//菜单修改
				menu_edit_para:{
					name:'',
					url:'',
					is_show:false,
					id:0,
					fid:0,
				},
			}
		},
		created() {
			
			//读取菜单
			this.get_data()
		},
		methods: {

			//状态操作
			status_click(item){

				//
				switch(item.status){
					case '1':
						var confirm_text="点击'确定'隐藏此菜单";
						var new_statue=2;
						break;
					case '2':
						var confirm_text="点击'确定'显示此菜单";
						var new_statue=1;
						break;
				}

				//询问
				this.$my.other.confirm({
					content:confirm_text,
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'menu_edit_by_admin',
								id:item.id,
								status:new_statue
							},
							callback:(data)=>{

								//报告结果
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});

								//刷新数据
								this.get_data()
							}
						})
					}
				})
			},
			
			//向上移动菜单
			menu_move(item_id,type){
				let this_menu=this.menu_buf[item_id]
				switch(type){
					case 'up':var another_menu=this.get_another_menu(this_menu,'last');break;
					case 'down':var another_menu=this.get_another_menu(this_menu,'next');break;
				}
				
				if(!another_menu){
					this.$my.other.msg({
						type:'success',
						str:'已在最顶端'
					});
					return;
				}
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_edit_by_admin',
						edit_data:[{
								id:this_menu.id,
								weight:another_menu.weight
							},{
								id:another_menu.id,
								weight:this_menu.weight
							}
						]
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
						this.get_data()
					}
				})
			},
			get_another_menu(this_menu,type){
				let menu_buf=false
				switch(type){
					case 'last':var weight_buf=0;break;
					case 'next':var weight_buf=1000000000;break;
				}
				for(let i in this.menu_buf){
					let item=this.menu_buf[i]
					
					this_menu.weight=parseInt(this_menu.weight)
					item.weight=parseInt(item.weight)
					
					if(type=='last'&&this_menu.fid==item.fid){
						if(this_menu.weight>item.weight&&weight_buf<item.weight){
							weight_buf=item.weight
							menu_buf=item
						}
					}else if(type=='next'&&this_menu.fid==item.fid){
						if(this_menu.weight<item.weight&&weight_buf>item.weight){
							weight_buf=item.weight
							menu_buf=item
						}
					}
				}
				return menu_buf
			},
			
			//删除菜单
			menu_del(item){

				//询问
				this.$my.other.confirm({
					content:'是否删除此菜单',
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'menu_del_by_admin',
								id:item.id,
							},
							callback:(data)=>{
								this.get_data()
							}
						})
					}
				})
			},

			//执行修改菜单
			menu_edit_sub(){

				if(!this.menu_edit_para.name){
					this.$my.other.msg({
						type:'warning',
						str:"请输入菜单名称"
					});
					return;
				}

				if(!this.menu_edit_para.url&&this.menu_edit_para.fid!=0){
					this.$my.other.msg({
						type:'warning',
						str:"子菜单链接地址必填"
					});
					return;
				}


				//调用接口
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_edit_by_admin',
						id:this.menu_edit_para.id,
						name:this.menu_edit_para.name,
						url:this.menu_edit_para.url,
					},
					callback:(data)=>{
						this.get_data()
						this.menu_edit_para.is_show=false;
						this.menu_edit_para.name='';
						this.menu_edit_para.url='';
						this.menu_edit_para.fid=0;
						this.menu_edit_para.id=0;
					}
				})
			},

			//修改菜单
			menu_edit(item){
				this.menu_edit_para.is_show=true
				this.menu_edit_para.name=item.name
				this.menu_edit_para.url=item.url
				this.menu_edit_para.fid=item.fid
				this.menu_edit_para.id=item.id
			},
			
			//添加菜单
			menu_add_sub(){

				if(!this.menu_add_para.name){
					this.$my.other.msg({
						type:'warning',
						str:"请输入菜单名称"
					});
					return;
				}

				if(!this.menu_add_para.url&&this.menu_add_para.fid!=0){
					this.$my.other.msg({
						type:'warning',
						str:"子菜单链接地址必填"
					});
					return;
				}


				//调用接口
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_add_by_admin',
						name:this.menu_add_para.name,
						fid:this.menu_add_para.fid,
						url:this.menu_add_para.url,
					},
					callback:(data)=>{
						this.get_data()
						this.menu_add_para.is_show=false;
						this.menu_add_para.fname='';
						this.menu_add_para.name='';
						this.menu_add_para.url='';
					}
				})
			},

			//打开添加菜单界面
			menu_add(fid){
				if(fid!=0){
					this.menu_add_para.fname=this.menu_buf[fid].name;
				}else this.menu_add_para.fname='无'

				this.menu_add_para.fid=fid;
				this.menu_add_para.is_show=true
			},

			//获取菜单列表
			get_data(){
				
				//加载中...
				if(this.loading){
					return;
				}

				//置为加载中
				this.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_list_by_admin',
					},
					callback:(data)=>{
						
						//加载完毕
						this.loading=false
						
						//格式化主菜单
						let menu_buf={}
						let menu_main=[];
						let menu_children={};
						for(var item of data.list){

							//状态
							switch(item.status){
								case '1':
									item.status_text='正常';
									item.status_btn='隐藏';
									break;
								case '2':
									item.status_text='隐藏';
									item.status_btn='显示';
									break;
							}

							menu_buf[item.id]={...item};

							if(item.fid==0){
								menu_main.push(item)
							}else{
								if(!menu_children[item.fid])menu_children[item.fid]=[];
								menu_children[item.fid].push(item)
							}
						}
						
						//格式化子菜单
						for(var i in menu_main){
							menu_main[i].children=menu_children[menu_main[i].id]
						}

						//置入数据
						this.$set(this,"menu_buf",menu_buf)
						this.$set(this,"data",menu_main)
						// this.menu_buf=menu_buf
						// this.data=menu_main
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 60px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>